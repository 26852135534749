import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Axios from 'axios'
// import VueEcharts from 'vue-echarts'
// import { use } from "echarts";
// import { CanvasRenderer } from "echarts/renderers";

// use([CanvasRenderer]);

export let axios = Axios.create()

axios.interceptors.request.use((config) => {
    // console.log(config);
    if (config.url !== '/data/login') {
        if (sessionStorage.token) {
            config.headers.token = sessionStorage.token
        }
    }
    return config
})

axios.interceptors.response.use((config) => {
    return config.data
})

export default function(Vue) {
    Vue.use(ElementUi)

    Vue.prototype.$http = axios
    // Vue.component('v-echart', VueEcharts)
}
