import request from './index'
// 栏目下新闻(纯图，新闻)列表
export const homeLoad = () => request.get('/asjs-admin/core/common/homeLoad')
// 栏目下新闻(纯图，新闻)列表
export const webNewsList = () => request.get('/asjs-admin/core/news/webNewsList')
// 新闻列表(首页筛选部位)      
// export const ParameterList = () => request.get('/core/news/webNewsParameterList?parameter')
export const ParameterList = (params = {}) => request.get(`/asjs-admin/core/news/webNewsParameterList?parameter=${params.parameter}`, params)
// 专家列表            
export const webDoctorList = () => request.get('/asjs-admin/core/doctor/webDoctorList')
// 科对应专家信息         
export const webDoctor = (params) => request.get('/asjs-admin/core/doctor/webDoctorSectionTypeList', { params })
// 医疗项目
export const webProjectList = () => request.get('/asjs-admin/core/news/webProjectList')
// 健康科普列表
export const webjianList = (params) => request.get('/asjs-admin/core/news/webjianList', { params })
// 获取指定新闻信息
export const webSelectById = (params) => request.get('/asjs-admin/core/news/webSelectById', { params })
// 医生排版
export const docwebList = () => request.get('/asjs-admin/core/rostering/webList')
// 医生排版
export const docTimeList = (params) => request.get('/asjs-admin/core/rostering/webTimeList', { params })
// 医生排版预约时选择日期获取
export const webTimeList = (params) => request.get('/asjs-admin/core/rostering/webTimeList', { params })
// 医生排版预约时选择日期获取
export const docTime = (params) => request.get('/asjs-admin/core/rostering/webDoctorList', { params })
// 测评类型列表
export const evaluation = () => request.get('/asjs-admin/core/evaluation/webList')
// 问题和选项列表type选分类
export const webList = (params) => request.get('/asjs-admin/core/problem/webList', { params })
// 提交用户测评
export const webCreate = (params) => request.post('/asjs-admin/core/userRegister/webCreate', params)
// 用户注册
export const webUserRegister = (params = {}) => request.post('/asjs-admin/core/userRegister/webUserRegister', params)
// 约管理-添加预约
export const createAppointment = (params = {}) => request.post('/asjs-admin/core/subscribe/webCreate', params)