<template>
  <div id="app">
    <!-- <h1 style="font-size: 0">安思医院官网</h1> -->
    <div class="block1">
      <router-view> </router-view>
    </div>
    <!-- <bb></bb> -->
    <!-- <B></B> -->
  </div>
</template>

<script>
import Home1 from "./views/Hmoe1.vue";
export default {
  // components:{ Home1 },
  data() {
    return {};
  },
  props: {
    a: {
      type: String,
      default: "",
    },
  },
  created() {
    function IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent; //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      // b;

      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    }
    if (IsPhone()) {
      console.log("移动");
    } else {
      console.log("pc");

      console.log(document.body.clientWidth + 0.0);
      this.a = document.body.clientWidth / 1280;
      document.getElementsByTagName("body")[0].style.setProperty("--a", this.a);
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      console.log("手机端");
      this.$router.push("/home_pho", () => {});
    } else {
      console.log("pc端");
      window.addEventListener("resize", () =>
        setTimeout(function () {
          location.reload();
        }, 10)
      );
      this.$router.push("/");
    }

    let u = navigator.userAgent;
    if (u.match(/AppleWebKit.*Mobile.*/) != null) {
      document
        .getElementsByTagName("meta")[2]
        .setAttribute(
          "content",
          "width=device-width, initial-scale=0.4, minimum-scale=0.4, maximum-scale=0.4, user-scalable=no"
        );
    }
  },
};
</script>


<style lang="scss">
* {
  margin: 0;
}
html,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  // width: 100%;
}
// body::v-deep {
//   margin: 0;
//   padding: 0;
// }
// 	body{
// 		height: 100%;
// 		margin: -0.5px !important;
// 		padding: 0;
// 		width: 100%;
// 	}
:root {
  --a: 1;
}
.block1 {
  zoom: var(--a);
}
.el-container {
  height: 100%;
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 0px 100px 0px 100px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: left;
  line-height: 0px;
  padding: 0px 100px 0px 100px;
  // padding-left: 400px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-carousel__indicators {
  // height: 26px;
  // padding-top: 300px;
  display: flex;
  justify-content: flex-end;
}
.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  opacity: 1;
}
.el-menu .el-menu-item.is-active {
  background-color: #1518d7;
}
.el-card__body {
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
a,
button {
  cursor: pointer;
}
</style>
