import axios from 'axios'

function camelCase(str) {
  // 日期格式的原样返回 只检测年 - 月
  let reg = /^\d{4}-\d{2}/
  if (reg.test(str)) {
    return str
  }
  let rdashAlpha = /-([a-z]|[0-9])/gi
  let rmsPrefix = /^-ms-/
  let fcamelCase = function (all, letter) {
    return (letter + '').toUpperCase()
  }
  return str.replace(rmsPrefix, 'ms-').replace(rdashAlpha, fcamelCase)
}

function changeHump(data) {
  let result = {}
  if (Array.isArray(data)) {
    result = []
  }

  for (var key in data) {
    let value = data[key]
    key = key.replace(/_/g, '-')
    key = key !== '-1' ? camelCase(key) : key
    if (value instanceof Object) {
      value = changeHump(value)
    }
    result[key] = value
  }
  return result
}

axios.defaults.timeout = 30000
// axios.defaults.baseUrl = 'http://www.asjsclinic.com/'
axios.defaults.baseURL = 'http://www.asjsclinic.com/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.transformRequest = [
  function (data, headers) {
    return data
  },
]

axios.interceptors.request.use(
  (config) => {
    if (!('Content-Type' in config.headers)) {
      //   config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      // }
      // } else {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (res) => {
    return changeHump(res.data)
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
