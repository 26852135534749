import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugin from './plugin'
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)
Vue.use(plugin)
Vue.config.productionTip = false



new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted() {
        // 这句是重点， 'render-event' 需要和 vue.config.js里的renderAfterDocumentEvent值一致
        document.dispatchEvent(new Event('render-event'))
    },
}).$mount('#app')

// import Vue from 'vue';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// import App from './App.vue';

// Vue.use(ElementUI);

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });
