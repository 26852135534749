import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let routes = [
    {
        path: '/',
        component: () => import('./views/Hmoe1'),
        // component: () => import('./views/c/c'),

        // name:'home',
        children: [
            {
                path: '/', name: 'home',
                meta: {
                    title: '首页',
                }, component: () => import('./views/a/a')
            },
            {
                path: '/medical', name: 'medical',
                meta: {
                    title: '诊疗',
                }, component: () => import('./views/b/b')
            },
            {
                path: '/publicize', name: 'publicize',
                meta: {
                    title: '科普',
                }, component: () => import('./views/c/c')
            },
            {
                path: '/assess', name: 'assess',
                meta: {
                    title: '测评',
                }, component: () => import('./views/d/d')
            },
            {
                path: '/bespeak', name: 'bespeak',
                meta: {
                    title: '预约',
                }, component: () => import('./views/e/e')
            },
            {
                path: '/contact', name: 'contact',
                meta: {
                    title: '我们',
                }, component: () => import('./views/f/f')
            },

            {
                path: '/doctors', name: 'doctor',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors')
            },
            {
                path: '/doctors-2', name: 'doctors-2',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors2')
            },
            {
                path: '/doctors-3', name: 'doctors-3',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors3')
            },

            {
                path: '/doctors2', name: 'doctors2',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors1-2')
            },
            {
                path: '/doctors/:id', name: 'doctors',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors')
            },
            {
                path: '/news', name: 'news',
                meta: {
                    title: '医生',
                }, component: () => import('./views/news/news')
            },
            {
                path: '/search', name: 'search',
                meta: {
                    title: '医生',
                }, component: () => import('./views/search/search')
            },
            // { path: '*', component: () => import('./views/main') }
        ]
    },
    {
        path: "/hmoe_pho",
        name: 'Hmoe_pho',
        component: () => import('./views/Hmoe_pho'),
        children: [
            {
                path: '/home_pho', name: 'home_pho',
                meta: {
                    title: '首页',
                },
                component: () => import('./views/a/a_pho')
            },
            {
                path: '/medical_pho', name: 'medical_pho',
                meta: {
                    title: '诊疗',
                }, name: 'b_pho', component: () => import('./views/b/b_pho')
            },
            {
                path: '/publicize_pho', name: 'publicize_pho',
                meta: {
                    title: '科普',
                }, component: () => import('./views/c/c_pho')
            },
            {
                path: '/assess_pho', name: 'assess_pho',
                meta: {
                    title: '测评',
                }, component: () => import('./views/d/d_pho')
            },
            {
                path: '/bespeak_pho', name: 'bespeak_pho',
                meta: {
                    title: '预约',
                }, component: () => import('./views/e/e_pho')
            },
            {
                path: '/contact_pho', name: 'contact_pho',
                meta: {
                    title: '我们',
                }, component: () => import('./views/f/f_pho')
            },

            {
                path: '/doctors_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors_pho')
            },
            {
                path: '/doctors-2_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors2_pho')
            },
            {
                path: '/doctors-3_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors3_pho')
            },
            {
                path: '/doctors2_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors1-2_pho')
            },
            {
                path: '/doctors_pho/:id',
                meta: {
                    title: '医生',
                }, component: () => import('./views/doctor/doctors_pho')
            },
            {
                path: '/news_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/news/news_pho')
            },
            {
                path: '/search_pho',
                meta: {
                    title: '医生',
                }, component: () => import('./views/search/search_pho')
            },
        ]
    }
]

export default new Router({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
